<template>
  <div>
    <NavTitle class="mb16" title="资源位类目" :showBack="false">
      <template #right>
        <div class="flex items-center">
          <el-switch :value="isResourceGray" active-color="#08C8BD" @input="grayChange"></el-switch>
          <span class="ml12 fs16">切换成灰度版</span>
        </div>
      </template>
    </NavTitle>
    <div class="cate-list">
      <div class="cate-item" @click="toConfigList('HOME_BANNER')">
        <img src="@/assets/images/resource-cate/home-poster.png">
        <div>首页海报配置</div>
      </div>
      <div class="cate-item" @click="toConfigList('HOME_BUTTON')">
        <img src="@/assets/images/resource-cate/home-cate.png">
        <div>首页金刚区配置</div>
      </div>
      <div class="cate-item" @click="toConfigList('HOME_TYPE_TAB')">
        <img src="@/assets/images/resource-cate/home-column.png">
        <div>首页分栏配置</div>
      </div>
      <div class="cate-item" @click="toConfigList('HOME_NAV_PIC')">
        <img src="@/assets/images/resource-cate/find-card.png">
        <div>首页瓷片导航配置</div>
      </div>
      <div class="cate-item" @click="toConfigList('FIND_BANNER')">
        <img src="@/assets/images/resource-cate/find-poster.png">
        <div>发现页海报配置</div>
      </div>
      <div class="cate-item" @click="toConfigList('FIND_BUTTON')">
        <img src="@/assets/images/resource-cate/find-cate.png">
        <div>发现页金刚区配置</div>
      </div>
      <div class="cate-item" @click="toConfigList('FIND_TYPE_TAB')">
        <img src="@/assets/images/resource-cate/find-column.png">
        <div>发现页分栏配置</div>
      </div>
      <div class="cate-item" @click="toConfigList('FIND_NAV')">
        <img src="@/assets/images/resource-cate/find-card.png">
        <div>发现页图片瓷片区</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import { mapState } from 'vuex'
export default {
  name: 'ResourceCate',
  components: {
    NavTitle
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['isResourceGray'])
  },
  created() {
    this.$eventBus.$emit('ResetConfigList')
  },
  methods: {
    toConfigList(type) {
      this.$router.push({
        path: '/resource/configList',
        query: {
          resType: type
        }
      })
    },
    grayChange(e) {
      console.log('灰度改变', e)
      this.$store.commit('SET_RESOURCE_GRAY', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.cate-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  padding: 0 16px;
  .cate-item {
    background-color: #fff;
    font-size: 16px;
    padding: 24px 0;
    text-align: center;
    cursor: pointer;
    img {
      width: 48px;
      height: 48px;
      margin-bottom: 16px;
    }
  }
}
</style>